$black-color: #000;
$white-color: #fff;
$light-yellow-color: #dfca73;
$red-color: #e96363;
$green-color: #16c547;
$orange-color: #fc954b;
$gray-color: #9e9e9e;
$primary-color: #00a2ff;
$primary-background-color: #fafafa;
$border-color: #dadada;
$btn-color: #303030;
$modal-background-color: #53535380;
$header-table-color: #f2f4ff;
$activated-color: #475be8;
$disabled-color: #ececec;
$second-chart-color: #e3e7fc;

:export {
  chartColor: $primary-color;
  secondChartColor: $second-chart-color;
  lineChartColor: #e6e6e6;
  expenseChartColor: $light-yellow-color;
}
