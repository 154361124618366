@import "../../styles/variables";

.container {
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/images/error-background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .contentContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .img {
      width: 35%;
      height: 40%;
      flex-shrink: 0;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-weight: 900;

      .title {
        color: #10439c;
        font-size: 7rem;
      }

      .subTitle {
        display: inline-block;
        color: #0e47a0;
        font-size: 3rem;
        text-transform: uppercase;
        margin-top: 1rem;
      }

      .text {
        font-size: 1.8rem;
        line-height: 1.3;
      }

      .homeBtn {
        color: #fff;
        font-size: 1.8rem;
        text-align: center;
        padding: 1rem 3rem;
        margin-top: 3rem;
        border-radius: 1rem;
        background-color: $primary-color;
      }
    }

    .list {
      position: absolute;
      top: 50%;
      right: 2rem;
      list-style: none;
      padding: 0;
      transform: translateY(-50%);

      .item {
        margin-bottom: 2rem;

        text-decoration: none;
        color: #333;
        font-size: 2rem;

        .linkIcon {
          width: 2.5rem;
          height: 2.5rem;

          path {
            fill: $primary-color;
          }
        }
      }
    }
  }
}
